<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup>
const { currentPortal } = usePortals()

useHead({
  htmlAttrs: {
    lang: currentPortal?.value?.portal?.lang ?? 'de'
  }
})
</script>

<style lang="css">
body {
  text-align: center;
}
</style>
