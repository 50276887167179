const usePortals = () => {
  const currentPortal = useState<object|null>(() => null)

  const loadCurrentPortal = async () => {
    if (currentPortal.value !== null) {
      return
    }

    const { $backend } = useNuxtApp()
    const { data: currentPortalData } = await $backend.portals.getCurrentPortal()

    currentPortal.value = currentPortalData.value.data
  }

  const isCurrentPortalHundeLodge = (): boolean => {
    if (currentPortal.value === null) {
      return false
    }

    const portal = currentPortal.value?.portal?.portal ?? ''

    return portal === 'http://www.hunde-lodge.de'
  }

  return {
    loadCurrentPortal,
    currentPortal: readonly(currentPortal),
    isCurrentPortalHundeLodge
  }
}

// export { usePortals }
export default usePortals
