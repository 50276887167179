import { useGtm } from '@gtm-support/vue-gtm'

class ContentGroupModule {
  sendContentGroup (contentGroup: string): void {
    const gtm = useGtm()
    if (!gtm?.enabled()) {
      return
    }

    const { $dataLayerPushInHead } = useNuxtApp()
    $dataLayerPushInHead({ content_group: `${contentGroup}` })
  }
}

export default ContentGroupModule
