import { createGtm } from '@gtm-support/vue-gtm'
import { type VueGtmUseOptions } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  const route = useRoute()
  const vermieter = (route.path.startsWith('/vermieter/magazin/'))

  const { currentPortal } = usePortals()
  const GtmId = currentPortal.value.portal.tenant === 'hpve'
    ? 'GTM-5TKZ88J' // harz-travel.de and sauerland-travel.de
    : vermieter === true ? 'GTM-KNWD3R' : 'GTM-5FXF6X' // Vermieterseite oder Travanto and regional portals

  const gtmOptions = {
    LOCAL: {
      googleTagManagerId: 'GTM-0',
      googleTagManagerEnabled: true,
      googleTagManagerDebug: true
    },
    TEST: {
      googleTagManagerId: 'GTM-0',
      googleTagManagerEnabled: true,
      googleTagManagerDebug: true
    },
    STAGING: {
      googleTagManagerId: GtmId,
      googleTagManagerEnabled: true,
      googleTagManagerDebug: false
    },
    PRODUCTION: {
      googleTagManagerId: GtmId,
      googleTagManagerEnabled: true,
      googleTagManagerDebug: false
    }
  }

  if (!Object.prototype.hasOwnProperty.call(gtmOptions, config.public.nuxtEnvironmentStage)) {
    return
  }

  nuxtApp.vueApp.use(createGtm({
    id: gtmOptions[config.public.nuxtEnvironmentStage].googleTagManagerId,
    defer: false,
    compatibility: false,
    enabled: gtmOptions[config.public.nuxtEnvironmentStage].googleTagManagerEnabled,
    debug: gtmOptions[config.public.nuxtEnvironmentStage].googleTagManagerDebug,
    loadScript: false,
    vueRouter: useRouter() as VueGtmUseOptions['vueRouter'],
    trackOnNextTick: false
  }))

  useHead({
    script: {
      children: `
  <!-- Google Tag Manager -->
  (function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${GtmId}');
  <!-- End Google Tag Manager -->
`,
      tagPriority: 'critical'
    }
  })

  return {
    provide: {
      dataLayerPushInHead: (data: object) => {
        useHead({
          script: {
            children: `dataLayer.push( ${JSON.stringify(data)} );`,
            tagPriority: 'critical'
          }
        })
      }
    }
  }
})
